import React from "react"
import { useContext } from "react"
import { string_translation, createMarkup } from "../../../utils"
import {useStaticQuery, graphql} from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import "./firstcontact.scss"

export default function FirstContact({data, stringTranslations}) {

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  return (
    <>
      <section className="contact-detail-section">
        <div className="container">
          <div className="mobile-show">
            <div className="top-logo">
              <img src="/images/contact-us/top-logo-mobile.svg" alt="Mobile Logo" />
            </div>
          </div>
          <div className="col-group">
            <div className="col-dt-8">
              <div className="col-group">
                <div className="col-dt-6">
                  <div className="contact-details-wrapper">
                    <div className="logo">
                      <img
                        src={data?.contactAddress?.icon?.localFile?.publicURL}
                        alt="Contact Address"
                      />
                    </div>
                    <div className="info">
                      <div className="title h2" dangerouslySetInnerHTML={createMarkup(data?.contactAddress?.title)}></div>
                      <div className="more-info" dangerouslySetInnerHTML={createMarkup(data.contactAddress.details)}>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-dt-6">
                  <div className="address-wrapper">
                    <div className="logo">
                      <img src={data?.contactDetail?.icon?.localFile?.publicURL} alt="Contact Details" />
                    </div>
                    <div className="info">
                      <div className="title h2">{data?.contactDetail?.title}</div>
                      <div className="more-info phone" dangerouslySetInnerHTML={createMarkup(data.contactDetail.details)}>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-dt-4">
              <div className="desktop-show">
                <div className="top-logo big-screen">
                  <img src="/images/contact-us/top-logo.svg" alt="Top Logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

