import React from "react"
import "../styles/style.scss"
import "../styles/css/swiper-bundle.min.css"
import Seo from '../components/seo'
import Layout from "../components/layout"
import WhoWeAre from "../components/WhoWeAre"
import FirstContact from "../components/FirstContactUs"
import GeneralEnquiry from "../components/GeneralEnquiryContactUs"
import { graphql } from "gatsby"
import GeneralEnquiryVendorManagement from "../components/GeneralEnquiryVendorManagement"
import GeneralEnquiryMediaCenter from "../components/GeneralEnquiryMediaCenter"
import GetintouchProjectContact from "../components/GetinTouchProjectContact"

export default function ContactUs(pageData) {
  const data = pageData?.data
  let seo = pageData?.pageContext?.node?.seo
  let translatedURI = pageData?.pageContext?.node?.translations[0]?.uri

  return (
    <>
      <Seo data={seo}/>
      <Layout translatedURI={translatedURI}>
        <div className="contact-us">
          <WhoWeAre
            title={data.wpPage.pageSubtitle?.subtitle}
            name={data.wpPage?.title}
            description={data.wpPage.pageDescription?.pageDescription}
          />
        </div>
        <FirstContact data={data.wpPage.contactUsFields} translations={data.stringTranslations}/>
        <GeneralEnquiry data={data?.wpPage?.contactUsFields?.contactUsForm}/>
        <GeneralEnquiryMediaCenter data={data?.wpPage?.contactUsFields?.contactUsFormMediaCenter}/>
        <GeneralEnquiryVendorManagement data={data?.wpPage?.contactUsFields?.contactUsFormVendorRelation}/>
        <GetintouchProjectContact data={data?.wpPage?.contactUsFields?.contactUsFormWorkZone}/>
      </Layout>
    </>
  )
}

export const pageQuery = (graphql` query contactData($databaseId:Int) {
  wpPage(databaseId: {eq: $databaseId}) {
    id
    title
    pageSubtitle {
      fieldGroupName
      subtitle
    }
    content
    date
    pageDescription {
      fieldGroupName
      pageDescription
    }
    contactUsFields {
      fieldGroupName
      contactAddress {
        details
        title
        icon {
          localFile {
            publicURL
          }
        }
      }
      contactDetail {
        details
        title
        icon {
          localFile {
            publicURL
          }
        }
      }
      contactUsForm
      contactUsFormMediaCenter
      contactUsFormVendorRelation
      contactUsFormWorkZone
    }
  }
  stringTranslations {
    advance_ar:translateString(language: AR, string: "ADVANCED")
    advance_en:translateString(language: EN, string: "ADVANCED")
    search_ar:translateString(language: AR, string: "SEARCH")
    search_en:translateString(language: EN, string: "SEARCH")
  }
}`);