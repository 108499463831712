import React, { useEffect,useState, useContext } from "react"
import "./generalenquiry.scss"

import { string_translation } from "../../../utils"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"

import Cf7FormWrapper from "../cf7-form-wrapper"
import attentionIcon from "../../../static/images/error-pages/attention-icon.svg"
import countries from "../../../static/countries"
import { useLocation } from '@reach/router'


function Form({ handler, isLoading, isSent, hasError, validationErrors, stringTranslations }) {
  const [formState, setFormState] = useState({})

  const handleFieldChange = (field, e) => {
    setFormState({
      ...formState,
      [field]: e.target.value,
    })
  }

  const handleFormSubmit = (e) => {
    handler(e, formState)
  }

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)




  const ErrorMessages = (errors) => {
    return (
      <div className="errorMessageContainer">
        <div className="errorTitleContainer">
          <img src={attentionIcon} className="attentionIcon" alt={string_translation(stringTranslations, "submission_error", locale)} />
          <span className="errorMessage">{string_translation(stringTranslations, "submission_error", locale)}</span>
        </div>
        <div className="errorBody">
          <p>{errors.errors}</p>
         
          <span className="tryAgain">{string_translation(stringTranslations, "try_again", locale)}</span>
        </div>
      </div>
    );
  }

  if(isSent) {
    navigate("/"+locale+'/success')
  }
  return (
    
    <form onSubmit={handleFormSubmit}>
      {/* <div>isLoading: {isLoading ? "Loading" : "false"}</div>
      <div>isSent: {isSent ? "Sent" : "false"}</div>
      <div>Error: {hasError || "null"}</div> */}



      <div className="errorsParent">{validationErrors ? <ErrorMessages errors={validationErrors} /> : ""}</div>

      <div className="get-in-touch-form">
        <div className="logo">
          <div className="desktop-show">
            <img
              src="/images/invest/form-logo-2.svg"
              alt="Invest"
            />
          </div>
          <div className="mobile-show">
            <img
              src="/images/invest/form-logo-mobile-2.svg"
              alt="Invest"
            />
          </div>
        </div>
        <div className="all-labels-wrappers">
        <div className="label-wrapper">
            <div className="col-group">
              <div className="col-dt-6 label-1">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations,"fname", locale)}
                  // value={firstname}
                  autoComplete="name"
                  onChange={(e) => handleFieldChange("fname", e)}
                />
              </div>
              <div className="col-dt-6 label-2">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations,"lname", locale)}
                  // value={lastname}
                  onChange={(e) => handleFieldChange("lname", e)}
                />
              </div>
            </div>
          </div>
          <div className="label-wrapper">
            <div className="col-group">
              <div className="col-dt-6 label-1">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations,"email", locale)}
                  // value={email}
                  autoComplete="home email"
                  onChange={(e) => handleFieldChange("email", e)}
                />
              </div>
              <div className="col-dt-6 label-2">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations,"phone", locale)}
                  // value={number}
                  autoComplete="home tel"
                  onChange={(e) => handleFieldChange("phone", e)}
                />
              </div> 
            </div>
          </div>
          <div className="label-wrapper">
            <div className="col-group">
              <div className="col-dt-6 label-1">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations,"company", locale)}
                  // value={mediaOutlet}
                  onChange={(e) => handleFieldChange("company", e)}
                />
              </div>
              <div className="col-dt-6 label-2">
                <div className="select-wrapper">
                  <select
                    // value={countryValue}
                    onChange={(e) => handleFieldChange("country", e)}
                  >
                    <option value="" disabled selected>
                      {string_translation(stringTranslations,"country", locale)+" "}
                    </option>
                    {countries.map((country, index) => (
                      <option key={"c"+index} value={country.code}>
                        {country[locale]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="label-wrapper last-label-wrapper">
            <div className="label">
              <input
                type="text"
                placeholder={string_translation(stringTranslations, "msg", locale)}
                // value={message}
                onChange={(e) => handleFieldChange("msg", e)}
              />
            </div>
          </div>
          <div className="label-wrapper last-label-wrapper">
          
            <input type="checkbox" required="required" name="pplocy" /> {string_translation(stringTranslations, "ihaveread", locale)} <a href={"/"+locale+"/privacy-policy"} >{string_translation(stringTranslations, "ppolicy", locale)}</a>
          
          </div>
          <div className="label-wrapper">
            <div className="submit-btn-wrapper">
              <div className="submit-btn">
                <button className="cta-btn" type="submit" disabled={isLoading}>{isLoading ? string_translation(stringTranslations, "loading", locale) : string_translation(stringTranslations, "submit", locale)}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default function GeneralEnquiryVendorManagement({ data }) {
  const [formvState, setFormvState] = useState(false)

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  const { stringTranslations, site } = useStaticQuery(graphql`query {
    stringTranslations {
      newsroom_form_smalltitle_ar:translateString(language: AR, string: "NEWSROOM FORM TITLE 1")
        newsroom_form_smalltitle_en:translateString(language: EN, string: "NEWSROOM FORM TITLE 1")
        vendor_form_ar:translateString(language: AR, string: "تواصل مع إدارة الموردين ")
        vendor_form_en:translateString(language: EN, string: "Contact Vendors Management")

        fname_ar:translateString(language: AR, string: "FIRST NAME")
        fname_en:translateString(language: EN, string: "FIRST NAME")
        lname_ar:translateString(language: AR, string: "LAST NAME")
        lname_en:translateString(language: EN, string: "LAST NAME")
        email_ar:translateString(language: AR, string: "EMAIL")
        email_en:translateString(language: EN, string: "EMAIL")
        phone_ar:translateString(language: AR, string: "PHONE")
        phone_en:translateString(language: EN, string: "PHONE")
        media_outlet_ar:translateString(language: AR, string: "MEDIA OUTLET")
        media_outlet_en:translateString(language: EN, string: "MEDIA OUTLET")
        country_ar:translateString(language: AR, string: "COUNTRY")
        country_en:translateString(language: EN, string: "COUNTRY")
        role_ar:translateString(language: AR, string: "الصفة")
        role_en:translateString(language: EN, string: "Role")
        company_ar:translateString(language: AR, string: "اسم الجهة")
        company_en:translateString(language: EN, string: "Company Name")
        msg_ar:translateString(language: AR, string: "Enquiry/Message")
        msg_en:translateString(language: EN, string: "Enquiry/Message")
        submit_ar:translateString(language: AR, string: "SUBMIT")
        submit_en:translateString(language: EN, string: "SUBMIT")
        try_again_ar:translateString(language: AR, string: "TRY AGAIN")
        try_again_en:translateString(language: EN, string: "TRY AGAIN")
        submission_error_ar:translateString(language: AR, string: "SUBMISSION ERROR")
        submission_error_en:translateString(language: EN, string: "SUBMISSION ERROR")
        loading_ar:translateString(language: AR, string: "LOADING")
        loading_en:translateString(language: EN, string: "LOADING")
      ihaveread_ar:translateString(language: AR, string: "أقر بأنني قد قرأت ووافقت على")
      ihaveread_en:translateString(language: EN, string: "I have read and agree to the")
      ppolicy_ar:translateString(language: AR, string: "سياسة الخصوصية")
      ppolicy_en:translateString(language: EN, string: "Privacy Policy.")
    }
    site {
      siteMetadata {
        formSubmissionUrl
      }
    }
  }`)

  const hideotherform = () => {
    var divsToHide = document.getElementsByClassName("vendormgmthide"); //divsToHide is an array
    for(var i = 0; i < divsToHide.length; i++){
        divsToHide[i].style.display = "none"; // depending on what you're doing
    }

    document.getElementsByClassName("arrowbuttonContactUs")[0].style.color="#000000";
    document.getElementsByClassName("arrowbuttonMediaCenter")[0].style.color="#000000";
    document.getElementsByClassName("arrowbuttonWorkZone")[0].style.color="#000000";
    if(formvState==null || formvState==undefined || !formvState){
      document.getElementsByClassName("arrowbuttonVendorManagement")[0].style.color="#00B2E3";

    document.getElementById("VendorManagementContactForm").style.display = "block";

    }else{
      document.getElementsByClassName("arrowbuttonVendorManagement")[0].style.color="#000000";
      document.getElementById("VendorManagementContactForm").style.display = "none";

    }

if(locale=="ar"){


  if(formvState==null || formvState==undefined || !formvState){
    var divsToHide = document.getElementsByClassName("leftarrowbuttonimageVendorManagementar"); 
    for(var i = 0; i < divsToHide.length; i++){
        divsToHide[i].style.display = "none"; 
    }
    var divsToshow = document.getElementsByClassName("downarrowbuttonimageVendorManagementar"); 
    for(var i = 0; i < divsToshow.length; i++){
      divsToshow[i].style.display = "block"; 
    }
  }else{
    var divsToHide = document.getElementsByClassName("leftarrowbuttonimageVendorManagementar"); 
    for(var i = 0; i < divsToHide.length; i++){
        divsToHide[i].style.display = "block"; 
    }
    var divsToshow = document.getElementsByClassName("downarrowbuttonimageVendorManagementar"); 
    for(var i = 0; i < divsToshow.length; i++){
      divsToshow[i].style.display = "none"; 
    }

  }
  
  var divsToHide = document.getElementsByClassName("downarrowbuttonimageMediaCenterar"); 
  for(var i = 0; i < divsToHide.length; i++){
      divsToHide[i].style.display = "none"; 
  }
  var divsToHide = document.getElementsByClassName("downarrowbuttonimageContactUsar"); 
  for(var i = 0; i < divsToHide.length; i++){
      divsToHide[i].style.display = "none"; 
  }

  var divsToHide = document.getElementsByClassName("downarrowbuttonimageWorkZonear"); 
  for(var i = 0; i < divsToHide.length; i++){
      divsToHide[i].style.display = "none"; 
  }


  var divsToshow = document.getElementsByClassName("leftarrowbuttonimageMediaCenterar"); 
  for(var i = 0; i < divsToshow.length; i++){
    divsToshow[i].style.display = "block"; 
  }
  var divsToshow = document.getElementsByClassName("leftarrowbuttonimageContactUsar"); 
  for(var i = 0; i < divsToshow.length; i++){
    divsToshow[i].style.display = "block"; 
  }
  var divsToshow = document.getElementsByClassName("leftarrowbuttonimageWorkZonear"); 
  for(var i = 0; i < divsToshow.length; i++){
    divsToshow[i].style.display = "block"; 
  }

}else{

  if(formvState==null || formvState==undefined || !formvState){
    var divsToHide = document.getElementsByClassName("rightarrowbuttonimageVendorManagementen"); 
    for(var i = 0; i < divsToHide.length; i++){
        divsToHide[i].style.display = "none"; 
    }

    var divsToshow = document.getElementsByClassName("downarrowbuttonimageVendorManagementen"); 
    for(var i = 0; i < divsToshow.length; i++){
      divsToshow[i].style.display = "block"; 
    }


  }else{
    var divsToHide = document.getElementsByClassName("rightarrowbuttonimageVendorManagementen"); 
    for(var i = 0; i < divsToHide.length; i++){
        divsToHide[i].style.display = "block"; 
    }

    var divsToshow = document.getElementsByClassName("downarrowbuttonimageVendorManagementen"); 
    for(var i = 0; i < divsToshow.length; i++){
      divsToshow[i].style.display = "none"; 
    }

  }
 
  var divsToHide = document.getElementsByClassName("downarrowbuttonimageMediaCenteren"); 
  for(var i = 0; i < divsToHide.length; i++){
      divsToHide[i].style.display = "none"; 
  }
  var divsToHide = document.getElementsByClassName("downarrowbuttonimageContactUsen"); 
  for(var i = 0; i < divsToHide.length; i++){
      divsToHide[i].style.display = "none"; 
  }

  var divsToHide = document.getElementsByClassName("downarrowbuttonimageWorkZoneen"); 
  for(var i = 0; i < divsToHide.length; i++){
      divsToHide[i].style.display = "none"; 
  }

 
  var divsToshow = document.getElementsByClassName("rightarrowbuttonimageMediaCenteren"); 
  for(var i = 0; i < divsToshow.length; i++){
    divsToshow[i].style.display = "block"; 
  }
  var divsToshow = document.getElementsByClassName("rightarrowbuttonimageContactUsen"); 
  for(var i = 0; i < divsToshow.length; i++){
    divsToshow[i].style.display = "block"; 
  }

  var divsToshow = document.getElementsByClassName("rightarrowbuttonimageWorkZoneen"); 
  for(var i = 0; i < divsToshow.length; i++){
    divsToshow[i].style.display = "block"; 
  }
}


if(formvState==null || formvState==undefined || !formvState){
setFormvState(true);
}else{
  setFormvState(false);
}
  }

  const location = useLocation()
  
  useEffect(() => {
    if(location.hash=="#vendor"){
      
      hideotherform();
    }
  });

  return (
    <>
      <section className="general-enquiry">
        <div className="get-in-touch">
          <div className="container">
            <div className="form-wrapper nopadform">
            <a className="text-no-decor" onClick={hideotherform}  href="#"> <div onClick={hideotherform} className="big-title h2 arrowbuttonVendorManagement">{string_translation(stringTranslations, "vendor_form", locale)}
              <img className={"leftarrowbuttonimageVendorManagement"+locale} src="/images/contact-us/left.png"  alt="Arrow Icon"/>
              <img className={"rightarrowbuttonimageVendorManagement"+locale} src="/images/contact-us/right.png"  alt="Arrow Icon"/>
              <img className={"downarrowbuttonimageVendorManagement"+locale} src="/images/contact-us/down.png"  alt="Arrow Icon"/>
              </div> </a>
              <div className="get-in-touch-form-wrapper workzonehide mediacenterhide conatctushide" id="VendorManagementContactForm">
                <Cf7FormWrapper siteUrl={site.siteMetadata.formSubmissionUrl} formId={data}>
                  <Form stringTranslations={stringTranslations} />
                </Cf7FormWrapper>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
