import React, { useEffect,useState, useContext } from "react"
import "./getInProject.scss"
import {useStaticQuery, graphql, navigate } from "gatsby"
import Cf7FormWrapper from "../cf7-form-wrapper"
import { string_translation } from "../../../utils"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import attentionIcon from "../../../static/images/error-pages/attention-icon.svg"
import countries from "../../../static/countries"
import { useLocation } from '@reach/router'


function Form({ handler, isLoading, isSent, hasError, validationErrors, stringTranslations }) {
  const [formState, setFormState] = useState({})
  let roles=[
    {
      "en":"Owner",
      "ar":"مالك"
    },
    {
      "en":"Engineering Office / Architect",
      "ar":"مكتب هندسي / معماري"
    },
    {
      "en":"Potential Buyer",
      "ar":"مشتري محتمل"
    },
    {
      "en":"Agency / Agent",
      "ar":"وكالة / وكيل"
    },
    {
      "en":"Other",
      "ar":"أخرى"
    }


  ];

  const handleFieldChange = (field, e) => {
    setFormState({
      ...formState,
      [field]: e.target.value,
    })
  }

  const handleFileChange = (field, e) => {
    setFormState({
      ...formState,
      [field]: e.target.files[0],
    })
  }

 

  const handleFormSubmit = (e) => {
    handler(e, formState)
  }

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  const ErrorMessages = (errors) => {
    return (
      <div className="errorMessageContainer">
        <div className="errorTitleContainer">
          <img src={attentionIcon} className="attentionIcon" alt={string_translation(stringTranslations, "submission_error", locale)} />
          <span className="errorMessage">{string_translation(stringTranslations, "submission_error", locale)}</span>
        </div>
        <div className="errorBody">
        <p>{errors.errors}</p>
          <span className="tryAgain">{string_translation(stringTranslations, "try_again", locale)}</span>
        </div>
      </div>
    );
  }
  if(isSent) {
    navigate("/"+locale+'/success')
  }

  return (
    <form
      onSubmit={handleFormSubmit}
    >
      {/* <div>isLoading: {isLoading ? "Loading" : "false"}</div>
      <div>isSent: {isSent ? "Sent" : "false"}</div>
      <div>Error: {hasError || "null"}</div> */}

      <div className="errorsParent">{validationErrors ? <ErrorMessages errors={validationErrors} /> : ""}</div>

      <div className="get-in-touch-form">
        <div className="logo">
          <div className="desktop-show">
            <img
              src="/images/invest/form-logo-2.svg"
              alt="Form Logo"
            />
          </div>
          <div className="mobile-show">
            <img
              src="/images/invest/form-logo-mobile-2.svg"
              alt="Form Logo Mobile"
            />
          </div>
        </div>
        <div className="all-labels-wrappers">
          <div className="label-wrapper">
            <div className="col-group">
              <div className="col-dt-6 label-1">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations,"fname", locale)}
                  // value={firstname}
                  autoComplete="name"
                  onChange={(e) => handleFieldChange("fname", e)}
                />
              </div>
              <div className="col-dt-6 label-2">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations,"lname", locale)}
                  // value={lastname}
                  onChange={(e) => handleFieldChange("lname", e)}
                />
              </div>
            </div>
          </div>
          <div className="label-wrapper">
            <div className="col-group">
              <div className="col-dt-6 label-1">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations,"email", locale)}
                  // value={email}
                  autoComplete="home email"
                  onChange={(e) => handleFieldChange("email", e)}
                />
              </div>
              <div className="col-dt-6 label-2">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations,"phone", locale)}
                  // value={number}
                  autoComplete="home tel"
                  onChange={(e) => handleFieldChange("phone", e)}
                />
              </div> 
            </div>
          </div>
          <div className="label-wrapper">
            <div className="col-group">
              <div className="col-dt-6 label-1">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations,"role", locale)}
                  // value={mediaOutlet}
                  onChange={(e) => handleFieldChange("role", e)}
                />
              </div>
              <div className="col-dt-6 label-2">
             
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations,"complainlocation", locale)}
                  // value={mediaOutlet}
                  onChange={(e) => handleFieldChange("complainlocation", e)}
                />
               
              </div>
            </div>
          </div>
          <div className="label-wrapper">
            <div className="col-group">
              <div className="col-dt-6 label-1">
              <input
                type="text"
                placeholder={string_translation(stringTranslations, "msg", locale)}
                // value={message}
                onChange={(e) => handleFieldChange("msg", e)}
              />
              </div>
              <div className="col-dt-6 label-2" >
              <div className="select-wrapperr">
                <input
                  type="file"
                  title={string_translation(stringTranslations,"photoupload", locale)}
                  placeholder={string_translation(stringTranslations,"photoupload", locale)}
                  // value={mediaOutlet}
                  accept="image/*"
                  id="file-input"
                  onChange={(e) => handleFileChange("photoupload", e)}
                />

    <label id="file-input-label" for="file-input"
      >{string_translation(stringTranslations,"photoupload", locale)}</label>
             </div>
              </div>
            </div>
          </div>
          <div className="label-wrapper last-label-wrapper">
          
            <input type="checkbox" required="required" name="pplocy" /> {string_translation(stringTranslations, "ihaveread", locale)} <a href={"/"+locale+"/privacy-policy"} >{string_translation(stringTranslations, "ppolicy", locale)}</a>
          
          </div>

          <div className="label-wrapper">
            <div className="submit-btn-wrapper">
              <div className="submit-btn">
                <button className="cta-btn" type="submit" disabled={isLoading}>{isLoading ? string_translation(stringTranslations, "loading", locale) : string_translation(stringTranslations, "submit", locale)}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )

}

export default function GetintouchProjectContact({ data }) {
  const [formwState, setFormwState] = useState(false)

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  const { stringTranslations, site } = useStaticQuery(graphql`
    query {
      stringTranslations {
        project_form_smalltitle_ar:translateString(language: AR, string: "استفسارات الأعمال الإنشائية")
        project_form_smalltitle_en:translateString(language: EN, string: "Work Zone Enquiries")
        project_form_bigtitle_ar:translateString(language: AR, string: "NEWSROOM FORM TITLE 2")
        project_form_bigtitle_en:translateString(language: EN, string: "NEWSROOM FORM TITLE 2")

        fname_ar:translateString(language: AR, string: "FIRST NAME")
        fname_en:translateString(language: EN, string: "FIRST NAME")
        lname_ar:translateString(language: AR, string: "LAST NAME")
        lname_en:translateString(language: EN, string: "LAST NAME")
        email_ar:translateString(language: AR, string: "EMAIL")
        email_en:translateString(language: EN, string: "EMAIL")
        phone_ar:translateString(language: AR, string: "PHONE")
        phone_en:translateString(language: EN, string: "PHONE")
        media_outlet_ar:translateString(language: AR, string: "MEDIA OUTLET")
        media_outlet_en:translateString(language: EN, string: "MEDIA OUTLET")
        country_ar:translateString(language: AR, string: "COUNTRY")
        country_en:translateString(language: EN, string: "COUNTRY")
        role_ar:translateString(language: AR, string: "الصفة")
        role_en:translateString(language: EN, string: "Role")
        msg_ar:translateString(language: AR, string: "Enquiry/Message")
        msg_en:translateString(language: EN, string: "Enquiry/Message")
        submit_ar:translateString(language: AR, string: "SUBMIT")
        submit_en:translateString(language: EN, string: "SUBMIT")
        try_again_ar:translateString(language: AR, string: "TRY AGAIN")
        try_again_en:translateString(language: EN, string: "TRY AGAIN")
        submission_error_ar:translateString(language: AR, string: "SUBMISSION ERROR")
        submission_error_en:translateString(language: EN, string: "SUBMISSION ERROR")
        loading_ar:translateString(language: AR, string: "LOADING")
        loading_en:translateString(language: EN, string: "LOADING")
        complainlocation_ar:translateString(language: AR, string: "رابط موقع الشكوى")
        complainlocation_en:translateString(language: EN, string: "Complain Location Link")
        photoupload_ar:translateString(language: AR, string: "إرفاق صورة (إن وجد)")
        photoupload_en:translateString(language: EN, string: "Attach a photo (if available)")
        ihaveread_ar:translateString(language: AR, string: "أقر بأنني قد قرأت ووافقت على")
        ihaveread_en:translateString(language: EN, string: "I have read and agree to the")
        ppolicy_ar:translateString(language: AR, string: "سياسة الخصوصية")
        ppolicy_en:translateString(language: EN, string: "Privacy Policy.")
        
      }
      site {
        siteMetadata {
          formSubmissionUrl
        }
      }
    }
  `)
 
  const hideotherform = () => {
    var divsToHide = document.getElementsByClassName("workzonehide"); //divsToHide is an array
    for(var i = 0; i < divsToHide.length; i++){
        divsToHide[i].style.display = "none"; // depending on what you're doing
    }

    document.getElementsByClassName("arrowbuttonContactUs")[0].style.color="#000000";
    document.getElementsByClassName("arrowbuttonMediaCenter")[0].style.color="#000000";
    document.getElementsByClassName("arrowbuttonVendorManagement")[0].style.color="#000000";
    if(formwState==null || formwState==undefined || !formwState){
      document.getElementsByClassName("arrowbuttonWorkZone")[0].style.color="#00B2E3";
    document.getElementById("WorkZoneContactForm").style.display = "block";
    }else{
      document.getElementsByClassName("arrowbuttonWorkZone")[0].style.color="#000000";
      document.getElementById("WorkZoneContactForm").style.display = "none";
    }

if(locale=="ar"){

  if(formwState==null || formwState==undefined || !formwState){
    var divsToHide = document.getElementsByClassName("leftarrowbuttonimageWorkZonear"); 
    for(var i = 0; i < divsToHide.length; i++){
        divsToHide[i].style.display = "none"; 
    }

    var divsToshow = document.getElementsByClassName("downarrowbuttonimageWorkZonear"); 
    for(var i = 0; i < divsToshow.length; i++){
      divsToshow[i].style.display = "block"; 
    }

  }else{
    var divsToHide = document.getElementsByClassName("leftarrowbuttonimageWorkZonear"); 
    for(var i = 0; i < divsToHide.length; i++){
        divsToHide[i].style.display = "block"; 
    }

    var divsToshow = document.getElementsByClassName("downarrowbuttonimageWorkZonear"); 
    for(var i = 0; i < divsToshow.length; i++){
      divsToshow[i].style.display = "none"; 
    }
  }
 
  var divsToHide = document.getElementsByClassName("downarrowbuttonimageVendorManagementar"); 
  for(var i = 0; i < divsToHide.length; i++){
      divsToHide[i].style.display = "none"; 
  }
  var divsToHide = document.getElementsByClassName("downarrowbuttonimageMediaCenterar"); 
  for(var i = 0; i < divsToHide.length; i++){
      divsToHide[i].style.display = "none"; 
  }
  var divsToHide = document.getElementsByClassName("downarrowbuttonimageContactUsar"); 
  for(var i = 0; i < divsToHide.length; i++){
      divsToHide[i].style.display = "none"; 
  }

 

  var divsToshow = document.getElementsByClassName("leftarrowbuttonimageVendorManagementar"); 
  for(var i = 0; i < divsToshow.length; i++){
    divsToshow[i].style.display = "block"; 
  }
  var divsToshow = document.getElementsByClassName("leftarrowbuttonimageMediaCenterar"); 
  for(var i = 0; i < divsToshow.length; i++){
    divsToshow[i].style.display = "block"; 
  }
  var divsToshow = document.getElementsByClassName("leftarrowbuttonimageContactUsar"); 
  for(var i = 0; i < divsToshow.length; i++){
    divsToshow[i].style.display = "block"; 
  }
}else{


  if(formwState==null || formwState==undefined || !formwState){
    var divsToHide = document.getElementsByClassName("rightarrowbuttonimageWorkZoneen"); 
    for(var i = 0; i < divsToHide.length; i++){
        divsToHide[i].style.display = "none"; 
    }

    var divsToshow = document.getElementsByClassName("downarrowbuttonimageWorkZoneen"); 
    for(var i = 0; i < divsToshow.length; i++){
      divsToshow[i].style.display = "block"; 
    }

  }else{
      var divsToHide = document.getElementsByClassName("rightarrowbuttonimageWorkZoneen"); 
      for(var i = 0; i < divsToHide.length; i++){
          divsToHide[i].style.display = "block"; 
      }
  
      var divsToshow = document.getElementsByClassName("downarrowbuttonimageWorkZoneen"); 
      for(var i = 0; i < divsToshow.length; i++){
        divsToshow[i].style.display = "none"; 
      }


  }
 

  var divsToHide = document.getElementsByClassName("downarrowbuttonimageVendorManagementen"); 
  for(var i = 0; i < divsToHide.length; i++){
      divsToHide[i].style.display = "none"; 
  }
  var divsToHide = document.getElementsByClassName("downarrowbuttonimageMediaCenteren"); 
  for(var i = 0; i < divsToHide.length; i++){
      divsToHide[i].style.display = "none"; 
  }
  var divsToHide = document.getElementsByClassName("downarrowbuttonimageContactUsen"); 
  for(var i = 0; i < divsToHide.length; i++){
      divsToHide[i].style.display = "none"; 
  }



  var divsToshow = document.getElementsByClassName("rightarrowbuttonimageVendorManagementen"); 
  for(var i = 0; i < divsToshow.length; i++){
    divsToshow[i].style.display = "block"; 
  }
  var divsToshow = document.getElementsByClassName("rightarrowbuttonimageMediaCenteren"); 
  for(var i = 0; i < divsToshow.length; i++){
    divsToshow[i].style.display = "block"; 
  }
  var divsToshow = document.getElementsByClassName("rightarrowbuttonimageContactUsen"); 
  for(var i = 0; i < divsToshow.length; i++){
    divsToshow[i].style.display = "block"; 
  }
}

if(formwState==null || formwState==undefined || !formwState){
setFormwState(true);
}else{
  setFormwState(false);
}
  }

  const location = useLocation()
  
  useEffect(() => {
    if(location.hash=="#work-zone"){
      
      hideotherform();
    }
  });
  return (
    <>
    <section className="general-enquiry">
      <div className="get-in-touch">
        <div className="container ">
          <div className="form-wrapper ">
          <a className="text-no-decor" onClick={hideotherform}  href="#"> <div onClick={hideotherform} className="big-title h2 arrowbuttonWorkZone">{string_translation(stringTranslations, "project_form_smalltitle", locale)}
            <img className={"leftarrowbuttonimageWorkZone"+locale} src="/images/contact-us/left.png" alt="Arrow Icon"/>
            <img className={"rightarrowbuttonimageWorkZone"+locale} src="/images/contact-us/right.png"  alt="Arrow Icon"/>
            <img className={"downarrowbuttonimageWorkZone"+locale} src="/images/contact-us/down.png"  alt="Arrow Icon"/>
            </div> </a>
            <div className="get-in-touch-form-wrapper conatctushide mediacenterhide vendormgmthide" id="WorkZoneContactForm">
              <Cf7FormWrapper siteUrl={site.siteMetadata.formSubmissionUrl} formId={data}>
                <Form stringTranslations={stringTranslations} />
              </Cf7FormWrapper>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}  
